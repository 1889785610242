import React from 'react';
import BlogPostLayout from '../components/blog/blog-post';

class BlogPostTemplate extends React.Component {
  render() {
    return <BlogPostLayout {...this.props} />
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
